@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,400&display=swap");

:root {
  --purple: #9900ff;
  --blue-gray: #6c757d;
  --black: #000000;
  --light-gray: #f2f2f267;
  --light-purple: #8b34c5;
  --background-gray: #f2f2f2;
  --white: #fff;
}

.app {
  /* Existing styles for light mode */
  background-color: var(--white);
  color: var(--black);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}

.main-nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow-x: hidden;
}
.main-nav-list {
  list-style-type: none;
  display: flex;
  padding: 0;
}
a {
  text-decoration: none;
  color: inherit;
}

.nav-item {
  margin: auto;
  cursor: pointer;
  color: #333;
  transition: transform 0.2s ease-in-out;
}

.nav-item:last-child {
  margin-right: 0;
  color: var(--purple);
}

.nav-item:hover {
  opacity: 0.8;
  color: var(--purple);
  transform: scale(1.1);
}

.logo {
  font-size: 24px;
  font-weight: bold;
  margin: 0 auto 0 0;
  flex-shrink: 0;
  flex-basis: auto;
  color: var(--blue-gray);
  animation: floating 3s ease-in-out infinite;
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
}
/* Existing CSS code */

@media (max-width: 466px) {
  .main-nav {
    flex-wrap: wrap;
    justify-content: space-between;
    overflow-x: auto;
    padding-bottom: 16px;
    position: relative; /* Add this line */
  }

  .logo {
    margin: auto;
    flex-basis: 100%;
    text-align: center;
  }

  .main-nav-list {
    flex-grow: 1;
    white-space: nowrap;
  }

  .nav-item {
    margin-right: 8px;
  }

  .nav-item:last-child {
    margin-right: 0;
  }

  /* Add the following styles */
  .main-nav::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 16px;
    background-image: linear-gradient(
      to left,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
    pointer-events: none;
  }
}

@keyframes floating {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(0);
  }
}

.hero {
  background-color: var(--background-gray);
  border-radius: 0 0 5px 5px;
  text-align: center;
}

.hero-content {
  max-width: 80%;
  height: auto;
  margin: 0.5rem auto;
  padding: 2rem;
}

.hero-title {
  font-size: 2.8rem;
  line-height: 1;
  color: var(--black);
  background: linear-gradient(to left, #d48723, var(--purple));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  word-break: break-word;
  overflow-wrap: break-word;
}

.hero-subtitle {
  font-size: 20px;
  line-height: 1.8;
  color: var(--blue-gray);
}

.emoji {
  font-size: 1.2rem;
  line-height: 1rem;
  margin-left: 0.5rem;
}

.hero-button {
  padding: 0.8rem 2rem;
  border: none;
  outline: none;
  color: var(--white);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 5px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-size: 1.2rem;
}

.hero-button:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.hero-button:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}

/* Media Queries */
@media (max-width: 400px) {
  .hero-content {
    max-width: 90%;
    padding: 1.5rem;
  }

  .hero-title {
    font-size: 2rem;
  }

  .hero-subtitle {
    font-size: 18px;
  }

  .emoji {
    font-size: 1rem;
    line-height: 0.8rem;
    margin-left: 0.3rem;
  }
}

/* About Styles */
/* Existing CSS code */
.about {
  background-image: url(https://images.pexels.com/photos/303383/pexels-photo-303383.jpeg);
  background-size: cover;
  background-position: center;
  padding: 2rem;
  color: #d6d6d6ea;
  text-shadow: 0.4px 0.4px 0.8px rgb(0, 0, 0);
  margin-top: -8px;
}

.about-text {
  font-size: 18px;
  line-height: 1.8;
  padding: 0 1.2rem;
}
.about-title {
  text-align: center;
}

.about-card {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  padding: 1rem;
}

.glass-card {
  background-color: rgba(255, 255, 255, 0.11);
  backdrop-filter: blur(4px);
  border-radius: 10px;
  padding: 2rem;
}

.container {
  height: auto;
  margin: 0 auto;
  border-radius: 5px 5px 0 0;
  padding: 1rem;
  background-color: #f1f1f1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  flex: 1 1 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #fff;
  border-radius: 10px;
  margin: 0.5rem;
  box-sizing: border-box;
  text-align: center;
}

.card-icon {
  font-size: 3rem;
  margin-top: 1rem;
}

.card-title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.card-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.card-list li {
  margin-bottom: 0.5rem;
  color: var(--blue-gray);
}

/* Media Query for Responsive Layout */

/* Projects Styles */

.projects {
  margin: 1rem 0;
}

.projects-header {
  font-size: 1.8rem;
  text-align: center;
}

hr {
  width: 50%;
  margin-bottom: 30px;
}

.project-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
}

/* needs finishing on responsiveness */

.project-card {
  width: 300px;
  background-color: var(--light-gray);
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.project-card img {
  width: 100%;
  height: auto;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.project-card h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.project-card p {
  color: var(--blue-gray);
  margin-bottom: 1rem;
}

.project-card a {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: var(--purple);
  color: #fff;
  text-decoration: none;
  border-radius: 0.25rem;
  transition: background-color 0.2s ease-in-out;
}

.project-card a:hover {
  background-color: var(--light-purple);
}

.project-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

@media (min-width: 768px) {
  .project-cards {
    flex-wrap: nowrap;
  }

  .project-card {
    flex-basis: 25%;
    max-width: 25%;
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.project-card {
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

/* Add any other custom styles you need */

/* Contact Styles */
.contact {
  background-color: #eeeeee;
  padding: 0.5rem 2rem;
  border-radius: 5px 5px 0;
}

.contact-title {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1.5rem;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.contact-input,
.contact-textarea {
  width: 86%;
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  outline: none;
  box-shadow: 0 0 5px rgba(153, 0, 255, 0.3);
  color: var(--blue-gray);
}

.contact-textarea {
  height: 150px;
  outline: none;
  max-width: 100%;
  box-shadow: 0 0 5px rgba(153, 0, 255, 0.3);
}

.contact-input::placeholder,
.contact-textarea::placeholder {
  color: var(--light-contrast);
}

.contact-input:focus,
.contact-textarea:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(153, 0, 255, 0.3);
}

.contact-button {
  padding: 0.4rem 0.8rem;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 5px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-size: 1.2rem;
  margin: 10px auto;
}

.contact-button:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.contact-button:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}

/* Footer Styles */
.footer {
  background-color: var(--blue-gray);
  color: #fff;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-icons {
  display: flex;
}

.footer-icon {
  color: #fff;
  font-size: 20px;
  margin-right: 10px;
  transition: opacity 0.2s ease-in-out;
}

.footer-icon:hover {
  transform: scale(1.3);
  opacity: 0.3;
}

.footer-text {
  font-size: 14px;
}

/*Responvineness*/

@media (max-width: 900px) {
  .project-cards {
    flex-wrap: wrap;
    justify-content: center;
  }

  .project-card {
    width: 300px;
    margin-bottom: 2rem;
  }
}

@media (max-width: 600px) {
  .container {
    padding: 0.5rem;
  }

  .card {
    flex-basis: calc(50% - 1rem);
    margin-bottom: 1rem;
  }
}
